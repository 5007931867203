import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, CircularProgress, Paper,
  Tabs, Tab, FormControl, Select, MenuItem, Grid,
  Input, InputLabel, Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Remove, Check, ChevronRight, SaveAlt, FilterList, FirstPage, LastPage, ChevronLeft, Search, Clear } from '@material-ui/icons';
import { navigate } from "gatsby"
import MaterialTable from 'material-table';
import withRoot from '../withRoot';
import Layout from "../components/layout";
import SEO from "../components/seo";
import FirebaseClient from '../clients/FirebaseClient';

const db = FirebaseClient.firestore();

const TableIcons = {
  Check: Check,
  ThirdStateCheck: Remove,
  DetailPanel: ChevronRight,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  Search: Search,
  ResetSearch: Clear,
}

const styles = theme => ({
  root: {
    flex: 1,
    paddingTop: theme.spacing.unit * 2,
  },
  consoleHeader: {
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  grow: {
    flexGrow: 1,
  }
});

class Welcome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userWeddings: [],
      weddingGuests: [],
      weddingId: '',
      wedding: null,
      announcementTitle: '',
      announcementBody: '',
      processing: true,
      tabIndex: 0
    };
  }

  componentDidMount() {
    FirebaseClient.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user, processing: false }, () => {
          this.fetchUserWeddings();
        });
      } else {
        navigate('/signin');
      }
    });
  }

  handleTabChange = (event, value) => {
    this.setState({ tabIndex: value });
  };

  handleannouncementTitleChange = event => {
    this.setState({ announcementTitle: event.target.value });
  };

  handleannouncementBodyChange = event => {
    this.setState({ announcementBody: event.target.value });
  };

  handleWeddingSelection = event => {
    this.setState({ weddingId: event.target.value, wedding: null, weddingGuests: [], weddingDetailsProcessing: true }, () => {
      this.fetchWedding();
      this.fetchWeddingGuests();
    });
  };

  handleSendAnnouncement() {
    const { announcementTitle, announcementBody } = this.state;
    if (announcementTitle.length < 3) {
      alert('Announcement Title should be atleast 3 characters');
      return;
    }
    if (announcementBody.length < 3) {
      alert('Announcement Body should be atleast 3 characters');
      return;
    }

    this.setState({ weddingDetailsProcessing: true });

    const allGuestsTokens = [];
    const tokenPromises = [];
    const announcementPromises = [];
    this.state.weddingGuestsIds.forEach((guestId) => {
      tokenPromises.push(
        db.collection(`weddings/${this.state.weddingId}/guests/${guestId}/devices`).get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              if (
                data.deviceExpoToken
                && !allGuestsTokens.includes(data.deviceExpoToken)
              ) {
                allGuestsTokens.push(data.deviceExpoToken);
              }
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ weddingDetailsProcessing: false });
          })
      );
    });

    Promise.all(tokenPromises).then(() => {
      allGuestsTokens.forEach((token) => {
        // Send notification
        announcementPromises.push(
          fetch('https://cors-anywhere.herokuapp.com/exp.host:443/--/api/v2/push/send', {
            body: JSON.stringify({
              to: token,
              title: announcementTitle,
              body: announcementBody,
              sound: 'default',
              data: {
                type: 'announcement',
                title: announcementTitle,
                body: announcementBody,
                weddingId: this.state.weddingId,
              },
            }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
          })
        );
      });
      Promise.all(announcementPromises).then(() => {
        alert(`Announcement sent to your ${this.state.weddingGuests.length} guests successfully`);
        this.setState({ weddingDetailsProcessing: false });
      });
    });
  }

  fetchUserWeddings() {
    db.collection(`users/${this.state.user.uid}/weddings`).get().then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc) => {
          this.setState(prevState => ({ userWeddings: [...prevState.userWeddings, doc] }));
        });
      }
    })
  }

  fetchWeddingGuests() {
    if (this.state.weddingId && this.state.weddingId !== '') {
      db.collection(`weddings/${this.state.weddingId}/guests`).get().then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            this.setState(prevState => ({ weddingGuestsIds: [...prevState.weddingGuestsIds, doc.id] }));
            this.setState(prevState => ({ weddingGuests: [...prevState.weddingGuests, doc.data()] }));
          });
          this.setState({ weddingDetailsProcessing: false });
        }
      });
    } else {
      this.setState({ weddingGuests: [], weddingDetailsProcessing: false });
    }
  }

  fetchWedding() {
    if (this.state.weddingId && this.state.weddingId !== '') {
      db.collection('weddings').doc(this.state.weddingId).get().then((doc) => {
        this.setState({ wedding: doc });
      });
    } else {
      this.setState({ wedding: null });
    }
  }

  ConsoleHeader() {
    return (
      <Grid container alignItems="center" justify="flex-start" alignContent="center" spacing={24}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6" color="inherit">
            Hi {this.state.user.displayName}!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          {this.WeddingSelector()}
        </Grid>
      </Grid>
    );
  }

  WeddingSelector() {
    return (
      <FormControl style={{ flexGrow: 1, width: "100%", }}>
        <InputLabel htmlFor="select-wedding">Select Wedding</InputLabel>
        <Select
          value={this.state.weddingId}
          onChange={this.handleWeddingSelection}
          inputProps={{
            name: 'weddingId',
            id: 'select-wedding',
          }}
        >
          <MenuItem value=''> 
            <em>None</em>
          </MenuItem>
          {this.state.userWeddings.map((wedding, index) =>
            <MenuItem key={index} value={wedding.id}>{wedding.data().displayName}</MenuItem>
          )}
          }
        </Select>
      </FormControl>
    )
  }

  WeddingDetails() {
    if(this.state.wedding && !this.state.weddingDetailsProcessing) {
      return (
        <MaterialTable
          icons={TableIcons}
          columns={[
            { title: 'Wedding Title', field: 'displayName' },
            { title: 'Partner B', field: 'groomName' },
            { title: 'Partner A', field: 'brideName' },
            { title: 'Trending', field: 'trending', type: 'boolean' },
          ]}
          data={[this.state.wedding.data()]}
          title="Wedding Details"
          options={{
            toolbar: false,
            paging: false
          }}
        />
      ) 
    } else if (this.state.weddingDetailsProcessing) {
      return <CircularProgress color="secondary" />
    } else {
      return <Typography>No wedding selected!</Typography>;
    }
  }

  GuestDetails() {
    if(this.state.wedding && this.state.weddingGuests && !this.state.weddingDetailsProcessing) {
      const dateOptions = {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZoneName: 'short',
      };
      const timeOptions = {
        hour: 'numeric', minute: 'numeric',
      };
      return (
        <MaterialTable
          icons={TableIcons}
          columns={[
            { title: 'Guest Name', field: 'userDisplayName' },
            { title: 'Guest Side', field: 'guestSide' },
            { 
              title: 'Last Updated', field: 'updated',
              render: rowData => 
              <div>
                {rowData.updated.toDate().toLocaleDateString('en-US', dateOptions)}
                {' at '}
                {rowData.updated.toDate().toLocaleTimeString('en-US', timeOptions)}
              </div>
            },
          ]}
          data={this.state.weddingGuests}
          title="Guest Details"
          options={{
            toolbar: true,
            paging: true
          }}
        />
      )
    } else if (this.state.weddingDetailsProcessing) {
      return <CircularProgress color="secondary" />
    } else {
      return <Typography>No wedding selected!</Typography>;
    }
  }

  AnnouncementTool() {
    if(this.state.wedding && this.state.weddingGuests && !this.state.weddingDetailsProcessing) {
      return (
        <Grid>
          <Typography variant="h6">Announcement notifications will be sent to your {this.state.weddingGuests.length} guests</Typography>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="announcement-title">Announcement Title</InputLabel>
            <Input id="announcement-title" name="announcement-title" autoFocus onChange={this.handleannouncementTitleChange} />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="announcement-body">Announcement Body</InputLabel>
            <Input name="announcement-body" id="announcement-body" onChange={this.handleannouncementBodyChange} />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => { this.handleSendAnnouncement() }}
          >
            Send Notification
          </Button>
        </Grid>
      ) 
    } else if (this.state.weddingDetailsProcessing) {
      return <CircularProgress color="secondary" />
    }  else {
      return <Typography>No wedding selected!</Typography>;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Layout>
        <SEO title="Console" keywords={[`wedink`, `wedding`]} />
        <div className={classes.root}>
          {this.state.processing && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress className={classes.progress} color="secondary" />
            </div>
          )}
          {!this.state.processing && (
            <div>
              <Paper position="static" className={classes.consoleHeader} color="default">
                { this.ConsoleHeader() }
              </Paper>
              <Paper position="static" color="secondary">
                <Tabs 
                  value={this.state.tabIndex} 
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  >
                  <Tab label="Wedding Details" />
                  <Tab label="Guests" />
                  <Tab label="Announcement Tool" />
                </Tabs>
                <div style={{ padding: 10 }}>
                  {this.state.tabIndex === 0 && this.WeddingDetails()}
                  {this.state.tabIndex === 1 && this.GuestDetails()}
                  {this.state.tabIndex === 2 && this.AnnouncementTool()}
                </div>
              </Paper>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Welcome));